import {SharedModule} from '../modules/shared.module';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LoadJsonService} from '../services/loadjson.service';
import {QuestionsLabelsService} from '../services/questionslabels.service';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {CustomMatPaginatorIntl} from './custom-paginator-intl/CustomMatPaginatorIntl';
import {OkDialogComponent} from './ok-dialog/ok-dialog.component';
import {ECaseSnackBarService} from '../services/snack-bar.service';
import {ValidationErrorDialogComponent} from './validation-error-dialog/validation-error-dialog.component';
import {RefreshDataService} from '../services/refresh-data.service';
import {GeneralDialogComponent} from './ecase-general-dialog.component';
import {SecuritycenterService} from '../services/securitycenter.service';
import {CrmPersonSearchService} from '../services/crm-person-search.service';
import {SnackBarComponent} from './snack-bar/snack-bar.component';
import {GeneraldialogComponent} from "./generaldialog/generaldialog.component";
import {ContactUsComponent} from "./contact-us/contact-us.component";
import {ECasePipesModule} from "../pipes/pipes.module";
import {ErrorCodeScreenComponent} from "./error-code-screen/error-code-screen.component";

@NgModule({
    declarations: [
        GeneraldialogComponent,
        OkDialogComponent,
        ValidationErrorDialogComponent,
        GeneralDialogComponent,
        SnackBarComponent,
        ContactUsComponent,
        ErrorCodeScreenComponent
    ],
    imports: [SharedModule, RouterModule, ECasePipesModule],
    providers: [LoadJsonService, QuestionsLabelsService, SecuritycenterService, CrmPersonSearchService,
        { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }, ECaseSnackBarService, RefreshDataService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        GeneraldialogComponent,
        ContactUsComponent,
        GeneralDialogComponent,
        ErrorCodeScreenComponent
    ]
})
export class ComponentModule {

}

