import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {Observable} from "rxjs/internal/Observable";

@Injectable({
  providedIn: 'root'
})
export class AccountingSystemService {

  constructor(private eCaseHttpService: EcaseHttpService) {
  }

  downloadActivityFile(financialSystemQualiacId): Observable<any> {
    return this.eCaseHttpService.get('/api/downloadActivityFile/' + financialSystemQualiacId, {observe: 'response', responseType: 'blob'});
  }

  getActivities(): Observable<any> {
    return this.eCaseHttpService.get('/api/getActivities');
  }


  getAccountingAccounts(): Observable<any> {
    return this.eCaseHttpService.get('/api/getAccountingAccounts');
  }

  getPrograms(): Observable<any> {
    return this.eCaseHttpService.get('/api/getPrograms');
  }

  public saveAccountingAccount(accountingAccount) {
    return this.eCaseHttpService.post('/api/addOrUpdateAccountingAccount', accountingAccount);
  }

  public deleteAccountingAccount(accountingAccount){
    return this.eCaseHttpService.post('/api/deleteAccountingAccount', accountingAccount);
  }

  getQualiacAxesAnalytiques(): Observable<any> {
    return this.eCaseHttpService.get('/api/getQualiacAxesAnalytiques');
  }

  public saveQualiacAxesAnalytique(qualiacAxesAnalytique) {
    return this.eCaseHttpService.post('/api/saveQualiacAxesAnalytique', qualiacAxesAnalytique);
  }

}
