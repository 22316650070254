import {EventEmitter, Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {FormGroup} from '@angular/forms';
import {LoginAuthenticationService} from "./login-authentication.service";


@Injectable({
  providedIn: 'root'
})
export class EligibilityService {
  isEligibilityApplicationSelected: boolean;
  isProjectOverview: boolean;
  isInstitution: boolean;
  isEligibility: boolean;
  isReview: boolean;
  isAwardFinalization: boolean;
  isPostAward: boolean;
  isAwfApproved: boolean;
  isMultiTeamFundingEnabled: boolean;
  dataSourceEligibility = [];
  selectedApplicationId;
  decisionId;
  selectedEligibility;
  documentArray = [];
  documentTypeArray = [];
  selectedApplicationStatusId;
  selectedApplicationUserEmail;
  updatedApplicationStatusSubject = new Subject();
  filterDeactivatedApplicationSubject = new Subject();
  projectOverviewForm: FormGroup;
  navigationToChildeComponent = false;
  navigationToChildePaymentsComponent = false;
  clickCancel = false;
  direction;
  itemActive = '';
  array = [];
  filterObject: any = {};
  checkForObject = false;
  isExpertReviewRequired: boolean;
  applicationStatus: number;
  counsellorEmailAddress: string;
  counsellorFullName: string;
  isPaDatesRelativeProjectStartDate: boolean;
  programTypeId: number;
  isPreapplicationFunded: string;
  isPreApplicationPhase: string;
  hasReviewCompleted: string;

  // ALL VARIABLES USED IN AWARD FINALIZATION TAB
  boardDecisionId;
  isTableInValid;
  isAuthorizationTableValid;
  isAWFFormSubmitted;

  logChangeList = [];
  boardDecisionDate;
  isApplicationAccessReadOnly = false;


  // ALL VARIABLES USED IN FINANCE TAB
  paymentScheduleDataSet: any[] = null;
  updateTotalEngagementAmount = new Subject();
  authorizationData: any = null;
  principalCandidate: any = null;
  paReportsTableList = [];
  isForOrganization: boolean;
  organizationName: any;
  isNotificationSentForThisDecision = false;

  totalDecisionAmount = new EventEmitter<number>();
  authorizedAmount;

  constructor(private eCaseHttpService: EcaseHttpService, private loginAuthenticationService: LoginAuthenticationService) {
  }

  getEmailTemplates(filterWithCrmEmailTemplateId?): Observable<any> {
    if (filterWithCrmEmailTemplateId) {
      return this.eCaseHttpService.get('/api/getAllEmailTemplate?filterWithCrmEmailTemplateId=' + filterWithCrmEmailTemplateId);
    } else {
      return this.eCaseHttpService.get('/api/getAllEmailTemplate');
    }
  }

  public getComplementaryInformationFormDataForFieldsInProjectDetails(formId: number, caseApplicationId: number): Observable<any> {
    return this.eCaseHttpService.post('/api/getComplementaryInformationFormDataForFieldsInProjectDetails', {
      'formId': formId,
      'caseJsonDataRepositoryId': -1,
      'caseApplicationId': caseApplicationId,
      'isFormForConsent': true
    });
  }

  public saveComplementaryInformationFormInProjectDetails(caseApplicationId: number, caseJsonDataRepositoryId: number, formPrj): Observable<any> {
    return this.eCaseHttpService.post('/api/saveComplementaryInformationFormInProjectDetails', {
      'caseApplicationId': caseApplicationId,
      'caseJsonDataRepositoryId': caseJsonDataRepositoryId,
      'formPrj': formPrj
    });
  }


  get onApplicationDeactivated() {
    return this.filterDeactivatedApplicationSubject.asObservable();
  }

  deactivateApplication(event): void {
    this.filterDeactivatedApplicationSubject.next(event);
  }


  getEligibilityApplications(applications): Observable<any> {
    return this.eCaseHttpService.post('/api/searchEligibleApplications ', applications);
  }

  generatePaymentSchedule(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/generatePaymentSchedule ', obj);
  }

  getSelectedApplication(applicationId): Observable<any> {
    return this.eCaseHttpService.get('/api/getSelectedApplication/' + applicationId);
  }

  getEmailTemplatesAndNotifications(applicationId): Observable<any> {
    return this.eCaseHttpService.get('/api/getEmailTemplatesAndNotifications/' + applicationId);
  }

  getApplicationDocuments(applicationId): Observable<any> {
    return this.eCaseHttpService.get('/api/getApplicationDocuments/' + applicationId);
  }

  performBatchEligibility(applications: number[]): Observable<any> {
    return this.eCaseHttpService.post('/api/performBatchEligibility ', applications);
  }

  performBatchNonFinancing(applications: number[]): Observable<any> {
    return this.eCaseHttpService.post('/api/performBatchNonFinancing ', applications);
  }

  getPrincipalOrganization(): Observable<any> {
    return this.eCaseHttpService.get('/api/getPrincipalOrganization');
  }


  redoConsolidation(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/redoConsolidation', obj);
  }

  getTemplateVariablesForApplication(applicationId, crmEmailTemplateId, lang, updatedParams): Observable<any> {
    return this.eCaseHttpService.get('/api/getTemplateVariablesForApplication',
      {
        'applicationId': applicationId,
        'crmEmailTemplateId': crmEmailTemplateId,
        'lang': lang,
        'updatedParams': updatedParams
      });
  }

  countTotalProjects(): Observable<any> {
    return this.eCaseHttpService.get('/api/countEligibleApplications');
  }

  getListOfCriteriasForSelectedApplication(applicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/getEligibilityCriteria', {'applicationId': applicationId});
  }

  getCounsellorsByCompetitionId(competitionId): Observable<any> {
    return this.eCaseHttpService.get('/api/getCounsellorsByCompetitionId/' + competitionId);
  }


  // ALL FINANCE SERVICES

  getApplicationFinancialDetails(caseApplicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/getApplicationFinancialDetails', {'caseApplicationId': caseApplicationId});
  }

  getApplicationFinancialDocuments(caseApplicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/getApplicationFinancialDocuments', {'caseApplicationId': caseApplicationId});
  }

  getExpertReviewRequiredDetails(caseApplicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/getExpertReviewRequiredDetails', {'caseApplicationId': caseApplicationId});
  }

  getPaymentSchedule(authorizationScheduleId): Observable<any> {
    return this.eCaseHttpService.post('/api/getPaymentSchedule', {
      'authorizationScheduleId': authorizationScheduleId,
      'caseApplicationId': +(this.selectedApplicationId)
    });
  }

  getFinancialDecisionDetails(caseApplicationId, finBoardDecisionId): Observable<any> {
    return this.eCaseHttpService.post('/api/getFinancialDecisionDetails', {
      'caseApplicationId': caseApplicationId,
      'finBoardDecisionId': finBoardDecisionId
    });
  }

  getCurrentFiscalYear(): Observable<any> {
    return this.eCaseHttpService.get('/api/getCurrentFiscalYear');
  }

  getCrmEmailTemplateForFinancialDecisions(): Observable<any> {
    return this.eCaseHttpService.get('/api/getCrmEmailTemplateForFinancialDecisions');
  }

  getCommissionDecisionDates(): Observable<any> {
    return this.eCaseHttpService.post('/api/getCommissionDecisionDates', {caseApplicationId: this.selectedApplicationId});
  }

  getBudgetChartsForApplication(): Observable<any> {
    return this.eCaseHttpService.post('/api/getBudgetChartsForApplication', {caseApplicationId: this.selectedApplicationId});
  }

  getRecipientTeamAndInstitutionForApplication(): Observable<any> {
    return this.eCaseHttpService.post('/api/getRecipientTeamAndInstitutionForApplication', {caseApplicationId: this.selectedApplicationId});
  }

  deleteFinancialAuthorizationSchedule(authorizationScheduleId): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteFinancialAuthorizationSchedule', {
      caseApplicationId: this.selectedApplicationId,
      authorizationScheduleId: authorizationScheduleId
    });
  }

  approveFinancialPayment(data): Observable<any> {
    return this.eCaseHttpService.post('/api/approveFinancialPayment', data);
  }

  approveFinancialCommitment(data): Observable<any> {
    return this.eCaseHttpService.post('/api/approveFinancialCommitment', data);
  }

  deletePaymentSchedule(paymentScheduleId): Observable<any> {
    return this.eCaseHttpService.post('/api/deletePaymentSchedule', {
      paymentScheduleId: paymentScheduleId
    });
  }

  deleteFinancialDecision(finBoardDecisionId): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteFinancialDecision', {
      finBoardDecisionId: finBoardDecisionId,
      caseApplicationId: this.selectedApplicationId
    });
  }

  deleteFinancialDecisionDocument(documentId): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteFinancialDecisionDocument', {
      documentId: documentId,
      caseApplicationId: this.selectedApplicationId
    });
  }

  deleteFinancialEngagementDocument(documentId): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteFinancialEngagementDocument', {
      documentId: documentId,
      caseApplicationId: this.selectedApplicationId
    });
  }

  addOrUpdateFinancialAuthorizationSchedule(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdateFinancialAuthorizationSchedule', {
      caseApplicationId: +(this.selectedApplicationId),
      authorizationSchedule: obj
    });
  }

  addOrUpdatePaymentSchedule(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdatePaymentSchedule', {paymentSchedule: obj});
  }

  addOrUpdateFinancialDecision(formData: FormData): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdateFinancialDecision', formData);
  }

  getFinancialComments(): Observable<any> {
    return this.eCaseHttpService.post('/api/getFinancialComments', {caseApplicationId: this.selectedApplicationId});
  }

  addOrUpdateFinancialComments(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdateFinancialComments', obj);
  }

  deleteFinancialComment(id): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteFinancialComment', {commentId: id});
  }

  addOrUpdateNotification(data): Observable<any> {
    return this.eCaseHttpService.post('/api/saveFinancialDecisionNotification', data);
  }

  deleteNotificationCommunication(id): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteFinBoardDecisionCommunication', {'finBoardDecisionCommunicationId': id});
  }


  /*  -------------------------------- End of finance screen services ----------------------------*/


  getAllTermsForSelectedTaxonomy(taxonomyId: string, taxonomyParentId?: string): Observable<any> {
    const obj = {
      taxonomyId: taxonomyId.toString(),
      taxonomyParentId: taxonomyParentId ? taxonomyParentId.toString() : undefined
    };
    return this.eCaseHttpService.post('/api/getAllTermsWithTaxonomyId', obj);
  }

  addDocument(applications): Observable<any> {
    return this.eCaseHttpService.post('/api/attachNewDocument', applications);
  }

  deleteDocument(docId): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteDocument', docId);
  }

  saveEligibilityApplication(data): Observable<any> {
    return this.eCaseHttpService.post('/api/saveEligibilityApplication', data);
  }

  getAllCriterias(): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllCriterias');
  }

  deleteSelectedCriteria(applicationId, criteriaId): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteEligibilityCriteria',
      {'caseApplicationID': applicationId, 'eligibilityCriteriaID': criteriaId});
  }

  addOrUpdateListOfCriterias(listOfCriterias): Observable<any> {
    return this.eCaseHttpService.post('/api/saveCriteriasForApplication', listOfCriterias);

  }

  getNotificationListForCaseApplication(applicationId): Observable<any> {
    return this.eCaseHttpService.get('/api/getNotificationListForCaseApplication/' + applicationId);
  }

  saveEligiblityCommunication(obj, attachments: File[]): Observable<any> {
    const formData = new FormData();
    for (const file of attachments) {
      if (file.name) {
        formData.append(file.name, file);
      }
    }
    formData.append('payLoad', JSON.stringify(obj));
    return this.eCaseHttpService.post('/api/saveEligiblityCommunication', formData);
  }

  deleteEligiblityCommunication(id): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteEligiblityCommunication', {'eligEligibilityCommunicationId': id});
  }


  /*  ALL SERVICES FOR AWARD FINALIZATION TAB*/

  getAwfInformationForProject(caseApplicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/getAwfInformationForProject', {
      'caseApplicationId': +(caseApplicationId)
    });
  }


  getUpdatedScheduledReports(caseApplicationId, paReportScheduleTypeId): Observable<any> {
    return this.eCaseHttpService.post('/api/getUpdatedScheduledReports', {
      'caseApplicationId': +(caseApplicationId),
      'paReportScheduleTypeId': +(paReportScheduleTypeId)
    });
  }

  saveAwfInformationForProject(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/saveAwfInformationForProject', obj);
  }

  addAwfDocument(formData: FormData): Observable<any> {
    return this.eCaseHttpService.post('/api/addAwfDocument', formData);
  }


  deleteAwfDocument(data): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteAwfDocument', data);
  }

  updateAwfStatus(awfId, newStatus, awfSendNotification?): Observable<any> {
    if (awfSendNotification && awfSendNotification.notificationDocuments && awfSendNotification.notificationDocuments.length > 0) {
      const formData = new FormData();
      for (const file of awfSendNotification.notificationDocuments) {
        if (file.name) {
          formData.append(file.name, file);
        }
      }
      delete awfSendNotification.notificationDocuments;
      formData.append('payLoad', JSON.stringify({
        'awfId': awfId,
        'newStatus': newStatus,
        'awfSendNotification': awfSendNotification
      }));
      return this.eCaseHttpService.post('/api/updateAwfStatusWithNotificationDocuments', formData);
    } else {
      return this.eCaseHttpService.post('/api/updateAwfStatus', {
        'awfId': awfId,
        'newStatus': newStatus,
        'awfSendNotification': awfSendNotification
      });
    }
  }

  generateAndAddFinEngagementTemplate(templateId, finEngagementId, lang, format, selectedVariables, documentTypeId, allVariablesObject, caseApplicationTeamId, caseApplicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/generateAndAddFinEngagementTemplate', {
      'finEngagementId': finEngagementId,
      'templateId': templateId,
      'lang': lang,
      'format': format,
      'selectedVariables': selectedVariables,
      'allVariablesObject': allVariablesObject,
      'documentTypeId': documentTypeId,
      'caseApplicationTeamId': caseApplicationTeamId,
      'caseApplicationId': caseApplicationId
    });
  }

  addFinEngagementDocument(formData: FormData): Observable<any> {
    return this.eCaseHttpService.post('/api/addFinEngagementDocument', formData);
  }

  sendFinEngagementNotification(obj, attachments: File[]): Observable<any> {
    const formData = new FormData();
    for (const file of attachments) {
      if (file.name) {
        formData.append(file.name, file);
      }
    }
    formData.append('payLoad', JSON.stringify(obj));
    return this.eCaseHttpService.post('/api/sendFinEngagementNotification', formData);
  }


  generateAndAddAwfTemplate(templateId, awfId, lang, format, selectedVariables, documentTypeId, allVariablesObject, caseApplicationTeamId): Observable<any> {
    return this.eCaseHttpService.post('/api/generateAndAddAwfTemplate', {
      'awfId': awfId,
      'templateId': templateId,
      'lang': lang,
      'format': format,
      'selectedVariables': selectedVariables,
      'allVariablesObject': allVariablesObject,
      'documentTypeId': documentTypeId,
      'caseApplicationTeamId': caseApplicationTeamId
    });
  }

  sendAwfDocumentsForSigning(signers, edmRepositoryDocumentIds, awfAwardFinalizationId, crmCommunicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/sendAwfDocumentsForSigning', {
      'signers': signers,
      'edmRepositoryDocumentIds': edmRepositoryDocumentIds,
      'awfAwardFinalizationId': awfAwardFinalizationId,
      'crmCommunicationId': crmCommunicationId,
      'redirectPath': (this.loginAuthenticationService.baseUrl + location.pathname)
    });
  }

  removeSignerFromAwfDocument(signers, crmCommunicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/removeSignerFromAwfDocument', {
      'signers': signers,
      'crmCommunicationId': crmCommunicationId
    });
  }

  getApproversForAwfDocument(edmDocumentRepositoryId, awfAwardFinalizationId): Observable<any> {
    return this.eCaseHttpService.get('/api/getApproversForAwfDocument/' + edmDocumentRepositoryId + '/' + awfAwardFinalizationId);
  }

  getApproversForFinancialDecisionDocument(edmDocumentRepositoryId, finBoardDecisionId): Observable<any> {
    return this.eCaseHttpService.get('/api/getApproversForFinancialDecisionDocument/' + edmDocumentRepositoryId + '/' + finBoardDecisionId);
  }

  getApproversForFinEngagementDocument(edmDocumentRepositoryId, finEngagementDocId): Observable<any> {
    return this.eCaseHttpService.get('/api/getApproversForFinEngagementDocument/' + edmDocumentRepositoryId + '/' + finEngagementDocId);
  }

  replaceAwfDocument(edmDocumentRepositoryId, awfAwardFinalizationTeamDocId, attachment: File): Observable<any> {
    const formData = new FormData();
    formData.append('fileToBeReplaced', attachment);
    formData.append('payLoad', JSON.stringify({
      'edmDocumentRepositoryId': edmDocumentRepositoryId,
      'awfAwardFinalizationTeamDocId': awfAwardFinalizationTeamDocId
    }));
    return this.eCaseHttpService.post('/api/replaceAwfDocument', formData);
  }

  startApprovalProcessForFinancialDecisionDocument(finBoardDecisionDocId, edmDocumentRepositoryId): Observable<any> {
    return this.eCaseHttpService.get('/api/startApprovalProcessForFinancialDecisionDocument/' + edmDocumentRepositoryId + '/' + finBoardDecisionDocId);
  }

  startApprovalProcessForFinancialEngagementDocument(finEngagementDocId, edmDocumentRepositoryId): Observable<any> {
    return this.eCaseHttpService.get('/api/startApprovalProcessForFinancialEngagementDocument/' + edmDocumentRepositoryId + '/' + finEngagementDocId);
  }

  archiveFinancialDecisionDocument(finBoardDecisionDocId, edmDocumentRepositoryId): Observable<any> {
    return this.eCaseHttpService.get('/api/archiveFinancialDecisionDocument/' + edmDocumentRepositoryId + '/' + finBoardDecisionDocId);
  }

  archiveFinancialEngagementDocument(finEngagementDocId, edmDocumentRepositoryId): Observable<any> {
    return this.eCaseHttpService.get('/api/archiveFinancialEngagementDocument/' + edmDocumentRepositoryId + '/' + finEngagementDocId);
  }

  replaceFinancialEngagementDocument(edmDocumentRepositoryId, finEngagementDocId, attachment: File): Observable<any> {
    const formData = new FormData();
    formData.append('fileToBeReplaced', attachment);
    formData.append('payLoad', JSON.stringify({
      'edmDocumentRepositoryId': edmDocumentRepositoryId,
      'finEngagementDocId': finEngagementDocId
    }));
    return this.eCaseHttpService.post('/api/replaceFinancialEngagementDocument', formData);
  }

  replaceFinancialDecisionDocument(edmDocumentRepositoryId, finBoardDecisionDocId, attachment: File): Observable<any> {
    const formData = new FormData();
    formData.append('fileToBeReplaced', attachment);
    formData.append('payLoad', JSON.stringify({
      'edmDocumentRepositoryId': edmDocumentRepositoryId,
      'finBoardDecisionDocId': finBoardDecisionDocId
    }));
    return this.eCaseHttpService.post('/api/replaceFinancialDecisionDocument', formData);
  }

  startApprovalProcessForAwfDocument(awfAwardFinalizationTeamDocId, edmDocumentRepositoryId): Observable<any> {
    return this.eCaseHttpService.get('/api/startApprovalProcessForAwfDocument/' + edmDocumentRepositoryId + '/' + awfAwardFinalizationTeamDocId);
  }

  archiveAwfDocument(awfAwardFinalizationTeamDocId, edmDocumentRepositoryId): Observable<any> {
    return this.eCaseHttpService.get('/api/archiveAwfDocument/' + edmDocumentRepositoryId + '/' + awfAwardFinalizationTeamDocId);
  }

  getSignersForAwfDocument(edmDocumentRepositoryId, awfAwardFinalizationId): Observable<any> {
    return this.eCaseHttpService.get('/api/getSignersForAwfDocument/' + edmDocumentRepositoryId + '/' + awfAwardFinalizationId);
  }

  sendCaseApplicationDocumentsForSigning(signers, edmRepositoryDocumentIds, caseApplicationId, crmCommunicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/sendCaseApplicationDocumentsForSigning', {
      'signers': signers,
      'edmRepositoryDocumentIds': edmRepositoryDocumentIds,
      'caseApplicationId': caseApplicationId,
      'crmCommunicationId': crmCommunicationId,
      'redirectPath': (this.loginAuthenticationService.baseUrl + location.pathname)
    });
  }

  removeSignerFromCaseApplicationDocument(signers, crmCommunicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/removeSignerFromCaseApplicationDocument', {
      'signers': signers,
      'crmCommunicationId': crmCommunicationId
    });
  }

  getSignersForCaseApplicationDocument(edmDocumentRepositoryId, caseApplicationId): Observable<any> {
    return this.eCaseHttpService.get('/api/getSignersForCaseApplicationDocument/' + edmDocumentRepositoryId + '/' + caseApplicationId);
  }

  sendFinBoardDecisionDocumentsForSigning(signers, edmRepositoryDocumentIds, finBoardDecisionId, crmCommunicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/sendFinBoardDecisionDocumentsForSigning', {
      'signers': signers,
      'edmRepositoryDocumentIds': edmRepositoryDocumentIds,
      'finBoardDecisionId': finBoardDecisionId,
      'crmCommunicationId': crmCommunicationId,
      'redirectPath': (this.loginAuthenticationService.baseUrl + location.pathname)
    });
  }

  removeSignerFromFinBoardDecisionDocument(signers, crmCommunicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/removeSignerFromFinBoardDecisionDocument', {
      'signers': signers,
      'crmCommunicationId': crmCommunicationId
    });
  }

  sendFinEngagementDocumentsForSigning(signers, edmRepositoryDocumentIds, finEngagementDocId, crmCommunicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/sendFinEngagementDocumentsForSigning', {
      'signers': signers,
      'edmRepositoryDocumentIds': edmRepositoryDocumentIds,
      'finEngagementDocId': finEngagementDocId,
      'crmCommunicationId': crmCommunicationId,
      'redirectPath': (this.loginAuthenticationService.baseUrl + location.pathname)
    });
  }

  removeSignerFromFinEngagementDocument(signers, crmCommunicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/removeSignerFromFinEngagementDocument', {
      'signers': signers,
      'crmCommunicationId': crmCommunicationId
    });
  }

  getSignersForFinBoardDecisionDocument(edmDocumentRepositoryId, finBoardDecisionId): Observable<any> {
    return this.eCaseHttpService.get('/api/getSignersForFinBoardDecisionDocument/' + edmDocumentRepositoryId + '/' + finBoardDecisionId);
  }

  getSignersForFinEngagementDocument(edmDocumentRepositoryId, finEngagementDocId): Observable<any> {
    return this.eCaseHttpService.get('/api/getSignersForFinEngagementDocument/' + edmDocumentRepositoryId + '/' + finEngagementDocId);
  }

  sendPaReportDocumentsForSigning(signers, edmRepositoryDocumentIds, paReportId, crmCommunicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/sendPaReportDocumentsForSigning', {
      'signers': signers,
      'edmRepositoryDocumentIds': edmRepositoryDocumentIds,
      'paReportId': paReportId,
      'crmCommunicationId': crmCommunicationId,
      'redirectPath': (this.loginAuthenticationService.baseUrl + location.pathname)
    });
  }

  removeSignerFromPaReportDocument(signers, crmCommunicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/removeSignerFromPaReportDocument', {
      'signers': signers,
      'crmCommunicationId': crmCommunicationId
    });
  }

  getSignersForPaReportDocument(edmDocumentRepositoryId, paReportId): Observable<any> {
    return this.eCaseHttpService.get('/api/getSignersForPaReportDocument/' + edmDocumentRepositoryId + '/' + paReportId);
  }

  downloadAllDocuments(caseApplicationId, edmRepositoryDocumentIds): Observable<any> {
    return this.eCaseHttpService.post('/api/zipAndDownloadAllDocuments', {
      'caseApplicationId': caseApplicationId,
      'edmRepositoryDocumentIds': edmRepositoryDocumentIds
    }, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  generateAwfTemplate(templateId, awfId, lang, format, selectedVariables, allVariablesObject): Observable<any> {
    return this.eCaseHttpService.post('/api/generateAwfTemplate', {
      'awfId': awfId,
      'templateId': templateId,
      'lang': lang,
      'format': format,
      'selectedVariables': selectedVariables,
      'allVariablesObject': allVariablesObject
    }, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  generateFinancialDecisionTemplate(templateId, caseApplicationId, lang, format, selectedVariables, allVariablesObject): Observable<any> {
    return this.eCaseHttpService.post('/api/generateFinancialDecisionTemplate', {
      'caseApplicationId': caseApplicationId,
      'templateId': templateId,
      'lang': lang,
      'format': format,
      'selectedVariables': selectedVariables,
      'allVariablesObject': allVariablesObject
    }, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  generateFinancialEngagementTemplate(templateId, caseApplicationId, lang, format, selectedVariables, allVariablesObject, finEngagementId): Observable<any> {
    return this.eCaseHttpService.post('/api/generateFinancialEngagementTemplate', {
      'caseApplicationId': caseApplicationId,
      'finEngagementId': finEngagementId,
      'templateId': templateId,
      'lang': lang,
      'format': format,
      'selectedVariables': selectedVariables,
      'allVariablesObject': allVariablesObject
    }, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  retrieveTemplateContentAndSender(templateId): Observable<any> {
    return this.eCaseHttpService.get('/api/retrieveTemplateContentAndSender/' + templateId );
  }

  checkForMultiValuedVariablesWithFinEngagementId(templateId, finEngagementId, selectedApplicationId, lang): Observable<any> {
    return this.eCaseHttpService.post('/api/checkForMultiValuedVariablesWithFinEngagementId', {
      'finEngagementId': finEngagementId,
      'templateId': templateId,
      'caseApplicationId': selectedApplicationId,
      'lang': lang,
    });
  }

  checkForMultiValuedVariablesWithFinBoardDecisionId(templateId, finBoardDecisionId, selectedApplicationId, lang): Observable<any> {
    return this.eCaseHttpService.post('/api/checkForMultiValuedVariablesWithFinBoardDecisionId', {
      'finBoardDecisionId': finBoardDecisionId,
      'templateId': templateId,
      'caseApplicationId': selectedApplicationId,
      'lang': lang,
    });
  }

  checkForMultiValuedVariablesWithAwfId(templateId, awfId, lang, caseApplicationTeamId): Observable<any> {
    return this.eCaseHttpService.post('/api/checkForMultiValuedVariablesWithAwfId', {
      'awfId': awfId,
      'templateId': templateId,
      'lang': lang,
      'caseApplicationTeamId': caseApplicationTeamId
    });
  }

  checkForMultiValuedVariablesWithCaseApplicationId(templateId, caseApplicationId, lang): Observable<any> {
    return this.eCaseHttpService.post('/api/checkForMultiValuedVariablesWithCaseApplicationId', {
      'caseApplicationId': caseApplicationId,
      'templateId': templateId,
      'lang': lang,
    });
  }

  checkForMultiValuedVariablesWithApplicationId(templateId, appId, lang): Observable<any> {
    return this.eCaseHttpService.post('/api/checkForMultiValuedVariablesWithApplicationId', {
      'caseApplicationId': appId,
      'templateId': templateId,
      'lang': lang,
    });
  }

  sendAwfNotification(obj, attachments: File[]): Observable<any> {
    const formData = new FormData();
    for (const file of attachments) {
      if (file.name) {
        formData.append(file.name, file);
      }
    }
    formData.append('payLoad', JSON.stringify(obj));
    return this.eCaseHttpService.post('/api/sendAwfNotification', formData);
  }

  sendFinancialDecisionNotification(obj, attachments: File[]): Observable<any> {
    const formData = new FormData();
    for (const file of attachments) {
      if (file.name) {
        formData.append(file.name, file);
      }
    }
    formData.append('payLoad', JSON.stringify(obj));
    return this.eCaseHttpService.post('/api/sendFinancialDecisionNotification', formData);
  }

  deleteAWFCommunication(id): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteAWFCommunication', {'awfAwardFinalizationCommunicationId': id});
  }

  getChangeLogList(projectId): Observable<any> {
    return this.eCaseHttpService.get('/api/getChangeLog/' + projectId);
  }

  getNotesForApplication(applicationId): Observable<any> {
    return this.eCaseHttpService.get('/api/getNotesForApplication/' + applicationId);
  }

  addNoteToApplication(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addNoteToApplication', obj);
  }

  deleteNoteFromApplication(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteNoteFromApplication', obj);
  }

  updateNoteToApplication(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/updateNoteToApplication', obj);
  }

  getReportScheduleTypes(projectId): Observable<any> {
    return this.eCaseHttpService.get('/api/getDistinctPAReportsScheduleType/' + projectId);
  }

  getPostAwardReports(applicationId): Observable<any> {
    return this.eCaseHttpService.get('/api/getPAReportByCaseApplicationId/' + applicationId);
  }

  addUpdatePostAwardReport(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addUpdatePostAwardReport', obj);
  }

  validatePaReport(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/validatePaReport', obj);
  }

  getCpmCompetitionPaReports(applicationId, isAmendment): Observable<any> {
    return this.eCaseHttpService.get('/api/getCpmCompetitionPaReports/' + applicationId +'/'+ isAmendment);
  }


  getPAReportHistory(reportId): Observable<any> {
    return this.eCaseHttpService.get('/api/getPostAwardReportHistory/' + reportId);
  }

  getPAReportDocuments(reportId): Observable<any> {
    return this.eCaseHttpService.get('/api/getPAReportDocuments/' + reportId);
    /*const obj = {
      "status":true,
      "docs": [
        {
          "fileName": "Principal application.pdf",
          "edmRepositoryId": 1319778,
          "creationDate": "2021-01-07T18:41:20.00Z",
          "createdBy": "System Synto",
          "documentTypeLabel": {
            "en": "Principal Candidate - Consolidated application",
            "fr": "Candidat principal - Candidature consolidée"
          },
          "cpmFormTypeLabel": {
            "en": "Grant application",
            "fr": "demande de Subvention"
          },
          "teamMemberRoleLabel": {
            "ar": "Backend",
            "en": "Admin",
            "fr": "Admin"
          },
          "documentTypeId": 7705,
          "cpmFormTypeId": 10109,
          "teamMemberRoleId": 2,
          "documentCategoryId": 1180,
          "documentCategoryLabel": {
            "ar": "Document Consolidation ar",
            "en": "Document Consolidation",
            "fr": "Consolidation de Documents"
          },
          "lastUpdatedDate": "2021-01-07T18:41:20.00Z"
        },
        {
          "fileName": "ADDITIONAL DOCS.pdf",
          "edmRepositoryId": 1319776,
          "creationDate": "2021-01-07T18:40:27.00Z",
          "createdBy": "Sahil Bhatoa",
          "documentTypeLabel": {
            "en": "Additional Documents",
            "fr": "Documents supplémentaires"
          },
          "cpmFormTypeLabel": {
            "en": "Grant application",
            "fr": "demande de Subvention"
          },
          "teamMemberRoleLabel": {
            "ar": "Principal candidate",
            "en": "Principal candidate",
            "fr": "Principal candidat"
          },
          "documentTypeId": 2032893,
          "cpmFormTypeId": 10109,
          "teamMemberRoleId": 10112,
          "documentCategoryId": 1182,
          "documentCategoryLabel": {
            "en": "Attached to application by application team member for AWF",
            "fr": "Attached to application by application team member for AWF"
          },
          "size": 30375,
          "lastUpdatedDate": "2021-01-07T18:40:27.00Z"
        }
      ]
    };
    return new Observable<any>((subscriber) => {
      subscriber.next(obj);
    });*/

  }

  archivePaReport(paReportId): Observable<any> {
    return this.eCaseHttpService.get('/api/archivePaReport/' + paReportId);
  }

  generatePdfForPaReport(paReportId, ssoUserId): Observable<any> {
    return this.eCaseHttpService.get('/api/generatePdfForAdminPaReport/' + paReportId + '/' + ssoUserId );
  }

  getSemanticalInfos(appId, listOfTermsIds): Observable<any> {
    return this.eCaseHttpService.post('/api/getSemanticalInfosOverview', {
      'caseApplicationId': appId,
      'terms': listOfTermsIds
    });
  }

  checkIfProjectCanBeClosed(appId): Observable<any> {
    return this.eCaseHttpService.get('/api/checkIfProjectCanBeClosed/' + appId);
  }

  getReviewDetailsForApplication(applicationId): Observable<any> {
    return this.eCaseHttpService.get('/api/getReviewDetailsForApplication/' + applicationId);
  }

  getPiFormRevision(caseApplicationId) {
    return this.eCaseHttpService.post('/api/getPiFormRevision', {
      'caseApplicationId': caseApplicationId
    });
  }

  addAdminCommentTextAreaToForm(formId: number, form: any): Observable<any> {
    return this.eCaseHttpService.post('/api/addAdminCommentTextAreaToForm', {
      'formId': formId,
      'form': (typeof form === 'string' ? JSON.parse(form) : form)
    });
  }

}
