import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Directive, QueryList, ViewChildren} from '@angular/core';
import {ECaseUtils} from './eCaseUtils';
import {ParametersService} from './services/parameters.service';
import {TranslateService} from '@ngx-translate/core';

/**
 * Created by sahilb@evision.ca on 2020-02-10.
 */
@Directive()
export class ECaseDataTable {

  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  showImage = true;
  exportMenu: any[] = ['ecase.admindashboard.admintools.excel', 'ecase.admindashboard.admintools.xml'];
  displayedColumns = []; // To be overridden by the child class
  sortActive; // To be overridden by the child class
  sortDirection; // To be overridden by the child class
  filterObject = {}; // To be overridden by the child class
  filterObjectForSelectFields = {}; // To be overridden by the child class
  serviceArray: any[] = []; // To be overridden by the child class's constructor
  dataSource = new MatTableDataSource(this.serviceArray);
  pageSizeOptions = [10];
  pageSize = 5;
  pageIndex = 1;
  originalArray: any[];
  translate: TranslateService;
  parameterService: ParametersService;
  syntoDate;
  syntoDateTime;
  syntoDateTimeWithHourAndMinute;

  // To be called in the child class's constructor by calling super(translate, parameterService) method
  constructor(translate: TranslateService, parameterService: ParametersService) {
    this.translate = translate;
    this.parameterService = parameterService;
    this.pageSize = Number(parameterService.parameter['/core/ui/mat_table_paginator_default_size']);
    this.pageSizeOptions = parameterService.parameter['/core/ui/mat_table_paginator_size/options'].split(',');
    this.syntoDate = this.parameterService.parameter['/core/ui/default_date/format'];
    this.syntoDateTime = this.parameterService.parameter['/core/ui/default_date_time_with_timezone/format'];
    this.syntoDateTimeWithHourAndMinute = this.parameterService.parameter['/core/ui/default_date_time_with_hour_and_minute/format'];
    this.sortDirection = 'desc';

  }

  @ViewChildren(MatSort) set matSort(ms: MatSort) {
    this.sort.toArray()[0] = ms;
    this.setDataSourceAttributes();
  }

  @ViewChildren(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator.toArray()[0] = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes(): void {
    this.renewDataSource(this.serviceArray);
  }

  renewDataSource(newDataArray): void {
    this.dataSource = new MatTableDataSource(newDataArray);
    this.sortingDataAccessorWithIgnoreCase();
    this.dataSource.paginator = this.paginator.toArray()[0];
    this.dataSource.sort = this.sort.toArray()[0];

  }

  sortingDataAccessorWithIgnoreCase(): void {
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }
      return data[sortHeaderId];
    };
  }

  sortColumn(event: any, childPath?: string): void {
    this.dataSource.data = ECaseUtils.sortColumn(event, this.dataSource.data, this.translate, childPath);
    this.renewDataSource(this.dataSource.data);
  }

  onPaginateChange(event): void {
    this.pageIndex = event.pageIndex + 1;
  }

  applyFilter(childPath?: string): void {
    const array = Object.keys(this.filterObject).filter(key => this.filterObject[key] !== '' && this.filterObject[key]);
    const arrayForSelect = Object.keys(this.filterObjectForSelectFields).filter(key => this.filterObjectForSelectFields[key] !== '');
    this.serviceArray = this.originalArray;
    if (array.length > 0) {
      array.forEach((key) => {
        this.serviceArray = this.serviceArray.filter((item) => {
          item = ECaseUtils.getObjectChildFromPath(item, childPath);
          if (typeof item[key] === 'object') {
            return item[key] &&
              (item[key])[this.translate.getDefaultLang()] &&
              (item[key])[this.translate.getDefaultLang()].toString().toLowerCase().includes(this.filterObject[key].toLowerCase());
          } else {
            if (isNaN(item[key])) {
              return (item[key] === 0 || item[key]) && item[key].toString().toLowerCase().includes(this.filterObject[key].toLowerCase());
            } else {
              return item[key].toString().toLowerCase().includes(this.filterObject[key].toString().toLowerCase());
            }
          }
        });
      });
      this.showImage = false;
    }
    if (arrayForSelect.length > 0) {
      arrayForSelect.forEach((key) => {
        this.serviceArray = this.serviceArray.filter((item) => {
          item = ECaseUtils.getObjectChildFromPath(item, childPath);
          if (typeof item[key] === 'boolean') {
            return item[key] !== undefined && item[key].toString() && item[key].toString() === this.filterObjectForSelectFields[key];
          } else {
            return (item[key] === 0 || item[key]) && item[key].toString() && item[key].toString() === this.filterObjectForSelectFields[key];
          }

        });
      });
      this.showImage = false;
    } else if (arrayForSelect.length > 0 || array.length > 0) {
      this.showImage = false;
    } else {
      this.showImage = true;
      this.serviceArray = this.originalArray;
    }
    this.setDataSourceAttributes();
  }

  resetFilters(resetDataSource: boolean): void {
    this.showImage = true;
    Object.keys(this.filterObject).map((key) => {
      this.filterObject[key] = '';
    });
    Object.keys(this.filterObjectForSelectFields).map((key) => {
      this.filterObjectForSelectFields[key] = '';
    });
    if (resetDataSource) {
      this.serviceArray = this.originalArray;
      this.setDataSourceAttributes();
    }
  }

}
