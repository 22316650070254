import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {throwError} from "rxjs/internal/observable/throwError";
import {MatProgressBarService} from './mat-progress-bar.service';
import {catchError, finalize} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {ErrorCodesService} from '../components/error-codes.service';
import {environment} from "../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class EcaseHttpService implements OnDestroy {

  windowId;
  httpOptions;
  counter;
  // This time is in seconds
  sessionTimeoutLimitBeforeExpiration = (60 * 55);
  // This time is in seconds
  sessionTimeoutLimitForShowingMessage = (60 * 5);
  sessionTimeoutInterval;
  trackKeysPerPageArray: any = {currentPath: '', parentPath: '', keys: [], parentKeys: [], isTab: false};
  private sessionSubject = new Subject<any>();

  constructor(private loaderService: MatProgressBarService, private http: HttpClient, private cookieService: CookieService,
              private router: Router, private errorCodesService: ErrorCodesService) {
    this.counter = 0;
    this.sessionTimeoutInterval = setInterval(() => {
      this.counter = this.counter + 1;
      if (this.counter === this.sessionTimeoutLimitBeforeExpiration) {
        this.checkForSessionTimout({status: true, counter: this.sessionTimeoutLimitForShowingMessage});
      }
    }, 1000);
  }

  get triggerSessionTimout(): Observable<any> {
    return this.sessionSubject.asObservable();
  }

  getAllKeysOfCurrentPage(): any {
    return this.trackKeysPerPageArray;
  }

  checkForSessionTimout(response): void {
    this.sessionSubject.next(response);
  }

  delete<T>(url: string, options?): Observable<any> {
    this.showLoader();
    this.counter = 0;
    this.prepareHeaders(options);
    return this.http.delete(url, this.httpOptions).pipe(catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
  }

  get<T>(url: string, options?, isIgnoreError?): Observable<any> {
    this.showLoader();
    this.counter = 0;
    this.prepareHeaders(options);
    return isIgnoreError ? this.http.get(url, this.httpOptions) : this.http.get(url, this.httpOptions).pipe(catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
  }

  head<T>(url: string, options?): Observable<any> {
    this.showLoader();
    this.counter = 0;
    this.prepareHeaders(options);
    return this.http.head(url, this.httpOptions).pipe(catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
  }

  jsonp<T>(url: string, callbackParam: string): Observable<any> {
    this.showLoader();
    this.counter = 0;
    return this.http.jsonp(url, callbackParam).pipe(catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
  }

  options<T>(url: string, options?): Observable<any> {
    this.showLoader();
    this.counter = 0;
    this.prepareHeaders(options);
    return this.http.options(url, this.httpOptions).pipe(catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
  }

  patch<T>(url: string, body: any | null, options?): Observable<any> {
    this.showLoader();
    this.counter = 0;
    this.prepareHeaders(options);
    return this.http.patch(url, body, this.httpOptions).pipe(catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
  }

  post<T>(url: string, body: any | null, options?, isIgnoreError?): Observable<any> {
    this.showLoader();
    this.counter = 0;
    this.prepareHeaders(options);
    return isIgnoreError ? this.http.post(url, body, this.httpOptions) : this.http.post(url, body, this.httpOptions)
      .pipe(catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
  }

  put<T>(url: string, body: any | null, options?): Observable<any> {
    this.showLoader();
    this.counter = 0;
    this.prepareHeaders(options);
    return this.http.put(url, body, this.httpOptions).pipe(catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
  }


  renewSession(): Observable<any> {
    return this.get('/api/renewSession');
  }

  ngOnDestroy(): void {
    clearInterval(this.sessionTimeoutInterval);
  }

  handleError(error): Observable<any> {
    let errorMessage: string;
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
      return throwError(() => new Error(errorMessage));
    } else {
      // server-side error
      if (error.status === 403) {
        this.checkForSessionTimout({status: true, counter: 0});
      }
      if (error.status === 504 && environment.production) {
        this.errorCodesService.errorCode = error.status;
        this.router.navigate(['/error']).then(() => {});
      }
      if (error.status >= 302 && error.status <= 399) { // Redirects
        errorMessage = JSON.stringify({
          'errorCode' : error.status,
          'errorMessage' : 'redirect',
        });
      } else {
        errorMessage = JSON.stringify({
          'errorCode' : error.status,
          'errorMessage' : error.message,
        });
      }
      if (JSON.parse(errorMessage)['errorMessage'] === 'redirect') {
        return of('Just a redirect');
      } else {
        return throwError(JSON.parse(errorMessage));
      }
    }
  }

  private prepareHeaders(options): any {
    this.httpOptions = {headers: new HttpHeaders()};
    if (options) {
      if (this.cookieService.check('XSRF-TOKEN')) {
        this.httpOptions.headers = this.httpOptions.headers.append('X-XSRF-TOKEN', this.cookieService.get('XSRF-TOKEN'));
      }
      this.httpOptions.headers = this.httpOptions.headers.append('window_id', this.windowId.toString());
      for (const key of Object.keys(options)) {
        if (options[key]) {
          this.httpOptions[key] = options[key].toString();
        }
      }
    } else {
      if (this.windowId) {
        this.httpOptions.headers = this.httpOptions.headers.append('window_id', this.windowId.toString());
      }
      if (this.cookieService.check('XSRF-TOKEN')) {
        this.httpOptions.headers = this.httpOptions.headers.append('X-XSRF-TOKEN', this.cookieService.get('XSRF-TOKEN'));
      }
    }
    return this.httpOptions;
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
