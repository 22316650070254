<div id="two-factor-authentication">
  <div *eCaseIfOnDom [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}" class="center-me">
    <mat-card fxLayout="column" style="width: 700px">
      <div *ngIf="showLanguageSwitcher">
        <mat-form-field appearance="outline" [style]="isMobile ? '' : 'width:30%;float:right;'">
          <mat-select (ngModelChange)="setLanguage($event)" [(ngModel)]="selectedLanguage">
            <mat-option *ngFor="let language of languages" [value]="language">{{language.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="logo" fxLayout="row" fxLayoutAlign="center center">
        <img *ngIf="isDropShadowEnabled"
             [src]="(!logoSrc || logoSrc === '') ? '/assets/images/logos/syntoLogo.svg' : logoSrc"
             alt="Synto product logo"
             style="filter: drop-shadow(0px 0px 5px #000);width:50%">
        <img *ngIf="!isDropShadowEnabled"
             [src]="(!logoSrc || logoSrc === '') ? '/assets/images/logos/syntoLogo.svg' : logoSrc"
             alt="Synto product logo" style="width:50%">
      </div>

      <form style="margin-top: 20px;" *ngIf="!isCodeSent" [formGroup]="twoFactorAuthenticationForm" name="forgotPasswordForm">

        <span>{{'ecase.common.2fa.description' | translate}}</span>
        <br>
        <br>
        <mat-radio-group  [formControlName]="'authenticationMode'" fxLayout="row" fxLayoutAlign="space-between">
          <mat-radio-button value="0">{{'ecase.common.email' | translate}}</mat-radio-button>
          <mat-radio-button [disabled]="!isAlternativeEmailAvailable"
                            value="1">{{'ecase.common.alternativeemail' | translate}}</mat-radio-button>
          <mat-radio-button [disabled]="!isPhoneNumberAvailable"
                            value="2">{{'ecase.common.mobiledevicenumber' | translate}}</mat-radio-button>
        </mat-radio-group>

        <br>

        <mat-label><span style="color: red">* </span>{{ 'ecase.common.alternativeemail' | translate }}</mat-label>
        <mat-form-field appearance="outline">
          <input [attr.aria-label]="'ecase.common.alternativeemail' | translate" formControlName="alternativeEmail"
                 matInput>
        </mat-form-field>

        <mat-label><span style="color: red">* </span>{{ 'ecase.common.mobiledevicenumber' | translate }}</mat-label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <mat-form-field appearance="outline" fxFlex="10">
            <input [attr.aria-label]="'ecase.common.countrycode' | translate" formControlName="countryCode"
                   matInput>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="30">
            <input [attr.aria-label]="'ecase.common.areacode' | translate" formControlName="areaCode"
                   matInput>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="50">
            <input [attr.aria-label]="'ecase.common.localnumber' | translate" formControlName="localNumber"
                   matInput>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center">
          <button (click)="sendSecurityCode()" [disabled]="twoFactorAuthenticationForm.invalid"
                  class="submit-button"
                  color="accent" mat-raised-button>
            {{ 'ecase.common.sendsecuritycode' | translate }}
          </button>
        </div>
      </form>

      <form style="margin-top: 20px;" *ngIf="isCodeSent" [formGroup]="verifyCodeForm" name="verifyCodeForm">
        <span >{{'ecase.common.2fa.description2' | translate}}</span>
        <br>
        <br>

        <mat-label><span style="color: red">* </span>{{ 'ecase.common.pleaseentersecuritycode' | translate }}</mat-label> <br>
        <mat-form-field appearance="outline" [style]="isMobile ? '' : 'width: 20%;'">
          <input [attr.aria-label]="'ecase.common.pleaseentersecuritycode' | translate" formControlName="securityCode"  maxlength="4"
                 matInput>
          <mat-error style="width:200px" *ngIf="verifyCodeForm.get('securityCode').errors?.required"> {{'ecase.common.thisfieldismandatory' | translate}}
          </mat-error>
        </mat-form-field>

        <br>
        <mat-label><span style="color: red">* </span>{{ 'ecase.common.isdevicetrusted' | translate }}</mat-label>
        <mat-radio-group [formControlName]="'isDeviceTrusted'" fxLayout="row">
          <mat-radio-button style="margin-left: 15px" *ngFor="let term of yesNoList" [value]="term.id">{{term.label[selectedLanguage.id]}}</mat-radio-button>
        </mat-radio-group>

        <div fxLayout="row" fxLayoutAlign="center center">
          <button (click)="verifyCodeAndSignIn()" [disabled]="verifyCodeForm.invalid"
                  class="submit-button"
                  color="accent" mat-raised-button>
            {{ 'login' | translate }}
          </button>

          <button (click)="sendSecurityCode()"
                  class="submit-button" style="margin-left: 15px"
                  color="accent" mat-raised-button>
            {{ 'ecase.common.resendsecuritycode' | translate }}
          </button>
        </div>

      </form>
      <br>
      <div class="login" fxLayout="row" fxLayoutAlign="center center">
        <a (click)="exitAndLogOut()" class="link"> {{ 'GOBACK' | translate }}</a>
      </div>
    </mat-card>
  </div>
</div>




